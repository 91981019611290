@import '@qwerty.software/auth-form/lib/auth-form.css';
@import '@qwerty.software/auth-form/lib/themes/wpf.css';
@import '@qwerty.software/auth-form/lib/themes/writepaperforme.css';
@import '@qwerty.software/auth-form/lib/themes/pc.css';
@import '@qwerty.software/auth-form/lib/themes/nu.css';
@import '@qwerty.software/auth-form/lib/themes/ev.css';
@import '@qwerty.software/auth-form/lib/themes/hwf.css';
@import '@qwerty.software/auth-form/lib/themes/mpe.css';
@import '@qwerty.software/auth-form/lib/themes/ne.css';
@import '@qwerty.software/auth-form/lib/themes/re.css';
@import '@qwerty.software/auth-form/lib/themes/rp.css';
@import '@qwerty.software/auth-form/lib/themes/sp.css';
@import '@qwerty.software/cookie-policy-notification/lib/cookie-policy-notification.css';
@import './../../../../framework/required-styles.scss';
